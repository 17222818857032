import React from 'react';
import Header from './Header';
import Projects from './Projects';
import Pharma from './Pharma';
import Devider from './Devider';
import TopBar  from './TopBar';
import VRCanvas from './VRCanvas';
import BottomBar from './BottomBar';
import './app.css';

function App() {
  return (
  <>
    <TopBar />
    <Header />
    <Devider />
    <Projects />
    <Pharma />
    <VRCanvas/>
    <BottomBar />
  </>
  )
}

export default App;
