import React from 'react'
import './header.css'
import './containers.css'
import { motion } from "framer-motion"

export default function Header() {

  const name = "name";
  const displayAction = true;
  const backColor = {
    backgroundColor: '#99C2A2',
    color: '#000101',
};
  return (
    <>
    <div className="headerContainer">
      <div id="TextContent">
        <motion.h1 
        variants={{  
          start: {opacity: 0, x: -200},
          end: {opacity: 1, x: 0}
        }}
        initial="start"
        animate="end"
        transition={{duration: 0.6}}
        className="title" id={name}>KRISTIAN AVLESBUG</motion.h1>
        {displayAction && 
        <motion.h3 
        variants={{  
          start: {opacity: 0, x: 200},
          end: {opacity: 1, x: 0}
        }}
        initial="start"
        animate="end"
        transition={{duration: 0.6}}
        className='subTitle' style = {backColor}>Developer</motion.h3>}
      </div>
    </div>
    </>
  );
};
