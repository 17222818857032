import React from 'react'
import './topBar.css'

export default function TopBar() {
  return (
    <>
    <div className='topBar'>
      <div className='logoBack'>
        <img className='topBarLogo' src="KALogoPastelBlack.png" alt="logo"></img>
      </div>
      <div className='filler'></div>
      <h3 className='topBarMenu'>MENU</h3>
    </div>
    </>
  );
}
