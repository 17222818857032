import React from 'react'
import './projects.css'
import okterlogo from './okterlogo.png'
import okterrender from './okterrender.png'

export default function Projects() {
    
      const titleStyle = {
        color: 'white',
        fontSize: '32px',
        textAlign: 'center',
        textDecoration: 'none',
        padding: "20%",
      };

      return (
        <div className="container">
          <div className="subContainer">
            <img style={{ width: "25%", borderRadius: "10%"}} src={okterlogo} alt="okter" />
          </div>
          <div className="subContainer">
            <a href="https://okter-7f509.firebaseapp.com/#/" style={titleStyle}>
              ØKTER
            </a>
          </div>
          <div className="subContainer">
            <img style={{ width: "60%"}} src={okterrender} alt="okterrender" />
          </div>
        </div>
      );
    };
    
