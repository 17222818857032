import React, { useEffect, useRef } from 'react'
import './vrcanvas.css'

export default function VRCanvas() {
    const canvasRef = useRef();
    const contextRef = useRef();
    const scrollRef = useRef();
    const toltalFrames = 151;
    useEffect(() => {
        contextRef.current = canvasRef?.current?.getContext("2d");
        
        const img = new Image();
        img.src = "./assets/0015.jpg"

        img.onload = () => {
            canvasRef.current.width = window.innerWidth;
            canvasRef.current.height = window.innerHeight;
            contextRef.current.drawImage(img, 0, 0,canvasRef.current.width, canvasRef.current.height);
        };

        const getImageName = (index) => `./assets/${index.toString().padStart(4, "0")}.jpg`;

        const preloadImages = () => {
            const imageLoadedPromises = [];
            for(let i = 15; i < toltalFrames; i++){
                const img = new Image();
                const imageLoadedPromise = new Promise((resolve, reject) => {
                    img.onload = () => resolve(img);
                    img.onerror = () => reject("Something went wrong");
                });
                imageLoadedPromises.push(imageLoadedPromise);
                img.src = getImageName(i);
            }

            return Promise.all(imageLoadedPromises);

        };

        const updateImage = (index) => {
            img.src = getImageName(index);
            contextRef.current.drawImage(img, 0, 0,canvasRef.current.width, canvasRef.current.height);
        };

        const handleScroll = (e) => {
            scrollRef.current = document.documentElement.scrollTop;
            const maxScrollTop = document.documentElement.scrollHeight - window.innerHeight;
            const scrollFraction = scrollRef.current / maxScrollTop;

            const frameIndex = Math.min(toltalFrames, Math.ceil(scrollFraction * toltalFrames));

            requestAnimationFrame(() => updateImage(frameIndex));
        };
        window.addEventListener("scroll", handleScroll);
        preloadImages();
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    },[])
  return (
    <>
    <section className={"section-container"}>
        <div className={"canvas-container"}>
            <canvas ref={canvasRef} id="scroll-canvas"/>
        </div>
    </section>
    <div>VRCanvas</div>
    </>
  )
}
