import React from 'react'
import './devider.css'

export default function Devider() {
  return (
    <h2 className="devider">
        Personal Projects
    </h2>
  )
}
