import React from 'react'
import './pharma.css'
import './containers.css'
import maleModel from './male_model.png'
import lungs from './SepperateLungs.png'
import gameEngine from './AdobeStock_GameEngine.png'

export default function Pharma() {
    
      const titleStyle = {
        color: 'white',
        fontSize: '32px',
        textAlign: 'center',
        textDecoration: 'none',
      };

      return (
        <div className="pharmaContainer">
          <div className="subContainer">
            <img style={{ width: "100%", borderRadius: "10%"}} src={maleModel} alt="male" />
          </div>
          <div className="subContainer">
            <a href="https://www.youtube.com/watch?v=gkEHYkIN1fc&feature=youtu.be&ab_channel=Kristian" style={titleStyle}>
            VR PHARMA
            </a>
          </div>
          <div className="subContainer">
            <div className="imageContainer">
              <img style={{ width: "60%", borderRadius: "10%"}} src={lungs} alt="lungs" />
              <img style={{ width: "40%", borderRadius: "10%"}} src={gameEngine} alt="gameEngine" />
            </div>
          </div>
        </div>
      );
}
